import React from 'react'

export const CloseIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="10.243" height="10.243" viewBox="0 0 16.243 16.243">
        <g id="Group_4723" data-name="Group 4723" transform="translate(2.121 2.121)">
            <g id="Group_4716" data-name="Group 4716" transform="translate(0)">
            <line id="Line_1079" data-name="Line 1079" x2="16.97" transform="translate(0 12) rotate(-45)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="3"/>
            <line id="Line_1080" data-name="Line 1080" y2="16.97" transform="translate(0 0) rotate(-45)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="3"/>
            </g>
        </g>
    </svg>
);
